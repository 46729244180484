import { useOrders } from '@chordcommerce/react-autonomy'

export function useOrderHistory() {
  const { isFetching, isLoaded, loadOrders, orders } = useOrders()

  if (!isLoaded && !isFetching) loadOrders()

  const getAlaCarteOrderHistory = () =>
    orders
      .filter((order) => order.state === 'complete')
      .filter(
        (order) =>
          order.channel !== 'subscriptions' &&
          !order.lineItems.find(
            (lineItem) => lineItem?.subscriptionLineItems?.length > 0
          )
      )

  const getClubOrderHistory = () =>
    orders
      .filter((order) => order.state === 'complete')
      .filter(
        (order) =>
          order.channel === 'subscriptions' ||
          !!order.lineItems.find(
            (lineItem) => lineItem?.subscriptionLineItems?.length > 0
          )
      )

  return {
    isFetching,
    isLoaded,
    getAlaCarteOrderHistory,
    getClubOrderHistory,
  }
}
